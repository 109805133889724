import {createRouter, createWebHistory} from 'vue-router'
import routes from './routes'
import store from '@/store';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {top: 0};
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath,
                },
            });
        } else next();
    } else if (to.matched.some((record) => record.meta.requiresGuest)) {
        if (store.getters.isAuthenticated) {
            next({
                path: 'home',
            });
        } else next();
    } else {
        next();
    }
});

export default router;