import axios from '@/plugins/axios'
import Cookies from 'js-cookie'

const state = {
    authenticated: false,
    user: null,
    token: Cookies.get('token'),
}

const mutations = {
    login(state, {data, remember}) {
        state.authenticated = true;
        state.token = data.token;
        state.user = data.user;
        Cookies.set('token', data.token, {expires: remember ? 30 : 7}) // save it for 7 days
    },
    logout(state) {
        state.authenticated = false;
        state.token = null;
        state.user = null;
        Cookies.remove('token');
    },
    setUser(state, data) {
        state.user = data;
    }
}

const actions = {
    login(context, credentials) {
        return axios.post('login', credentials)
            .then(res => {
                if (res.data.success) {
                    context.commit('login', {data: res.data, remember: credentials.remember})
                    return res;
                } else {
                    return false;
                }
            }).catch(err => {
                console.error(err);
                throw err;
            })
    },
    async register(context, data) {
        try {
            let res = await axios.post('register', data)
            if (res.data.success) {
                context.commit('login', {data: res.data, remember: false})
                return res.data;
            }
        } catch (err) {
            // console.error(err)
            throw err;
        }
    },
    async logout(context) {
        await axios.post('user/logout');
        context.commit('logout');
    },
    async fetchUser({commit}) {
        try {
            const res = await axios.get('user/current')
            commit('setUser', res.data.data)
        } catch (e) {
            console.error(e)
        }
    },
    async checkUser({commit, getters}) {
        if (getters.user == null && getters.isAuthenticated) {
            try {
                const res = await axios.get('user/current')
                commit('setUser', res.data.data)
            } catch (e) {
                console.error(e)
            }
        }
    },
    async updateUser(context, data) {
        try {
            let response = await axios.post('user/update', data)
            context.commit('setUser', response.data.data)
            return response;
        } catch (err) {
            console.error(err)
            throw err;
        }
    },
    async updateUserPassword(context, data) {
        try {
            let response = await axios.post('user/updatePassword', data)
            return response;
        } catch (err) {
            console.error(err)
            throw err;
        }
    }
}

const getters = {
    isAuthenticated(state) {
        return !!state.token;
    },
    user(state) {
        return state.user;
    },
    userToken(state) {
        return state.token;
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
};
