import axios from 'axios'
import store from '@/store'
import router from '@/router'

const instance = axios.create({
   // baseURL: `http://localhost:8000/api/v1`, // for local
   baseURL: `https://irwayn.oceansheet.com/api/v1`, // for deploy
   withCredentials: true,
});

instance.interceptors.request.use(
   config => {
      if(store.getters.userToken) {
         config.headers['Authorization'] = `Bearer ${store.getters.userToken}`;
      }

      return config;
   },
   error => {
      Promise.reject(error);
   }
);

instance.interceptors.response.use(response => response, error => {
   const { status, data } = error.response

  if( !status ) {
    alert("Network Error.")
  }

  if (status >= 500) {
    alert("Something went wrong.")
  }

   // if (status == 403 && data?.code === "onboard_already_done") {
   //    console.log()
   //    return this.$router.push({ name: 'calendar.index' });
   // }

   // if (status == 403 && data?.code === "onboard_not_done") {
   //    return this.$router.push({ name: 'onboard.step1',
   //       params: { uuid: error.response?.data?.user_uuid } 
   //    });
   // }

   // TODO: check in routes file add middleware
   // - isAuthenticatedAndActive (email verified & boarding steps done)
   // - isAuthenticatedAndNotActive (email verified & boarding steps not done yet)
   // if email not verified, just show pop up fiha verify email and block the screen from being clickable
   // and remove any close button

   if ((status === 401 || status == 403) && store.getters['isAuthenticated']) {
      // sweetalert.showConfirmAlert('error', 'Session timed out!', 'Please log in again to continue.', 'Confirm', 'Cancel', () => {
      //    store.commit('logout')
      //    console.log('logged out!');
      //    router.push({ name: 'login' })
      // });
     
        alert("Session timed out!").then(() => {
          store.commit('logout')
          router.push({ name: 'login' })
        })
   }

   return Promise.reject(error)
})

export default instance;