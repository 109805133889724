import AuthLayout from '@/layouts/Auth.vue'
import DefaultLayout from '@/layouts/Default.vue'
import MainLayout from '@/layouts/Main.vue'

export default [
    /***** Auth *****/
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Log In | OceanSheet',
            requiresGuest: true,
            layout: AuthLayout,
        },
        component: () => import('@/views/Auth/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            title: 'Register | OceanSheet',
            requiresGuest: true,
            layout: AuthLayout,
        },
        component: () => import('@/views/Auth/Register.vue')
    },
    {
        path: '/password/reset',
        name: 'password.request',
        meta: {
            title: 'Reset Password | OceanSheet',
            requiresGuest: true,
            layout: AuthLayout,
        },
        component: () => import('@/views/Auth/Password/Email.vue')
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset',
        meta: {
            title: 'Reset Password | OceanSheet',
            requiresGuest: true,
            layout: AuthLayout,
        },
        component: () => import('@/views/Auth/Password/Reset.vue')
    },
    {
        path: '/email/verify/:id',
        name: 'verification.verify',
        meta: {
            title: 'Verify Email | OceanSheet',
            requiresAuth: true,
            layout: AuthLayout,
        },
        component: () => import('@/views/Auth/Verification/Verify.vue')
    },
    {
        path: '/email/resend',
        name: 'verification.resend',
        meta: {
            title: 'Resend Verification | OceanSheet',
            requiresAuth: true,
            layout: AuthLayout,
        },
        component: () => import('@/views/Auth/Verification/Resend.vue')
    },

    /***** Home *****/
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Home | OceanSheet',
            layout: MainLayout,
        },
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            title: 'Dashboard | OceanSheet',
            requiresAuth: true,
            layout: DefaultLayout,
        },
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/flow',
        name: 'flow',
        meta: {
            title: 'OceanSheet',
            requiresAuth: true,
            layout: DefaultLayout,
        },
        component: () => import('@/views/FlowView.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: 'OceanSheet',
            requiresAuth: true,
            layout: DefaultLayout,
        },
        component: () => import('@/views/AboutView.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/ConnectionTest.vue')
    },
    {
        path: '/callback',
        name: 'callback',
        component: () => import('@/views/CallBack.vue')
    },

    // {
    //     path: '/customers/groups/:uuid/edit',
    //     name: 'customers.group.edit',
    //     meta: {
    //         title: 'Edit appointment group',
    //         layout: ApplicationLayout,
    //     },
    //     component: () => import('@/views/Application/Customers/Group/Edit.vue')
    // },

    // TODO: redirect to home website: terms page
    {
        path: '/terms-and-conditions',
        name: 'terms',
        beforeEnter() {
            window.location.href = 'https://www.oceansheet.com/terms-and-conditions';
        },
    },

    /***** 404 *****/
    {
        path: '/:catchAll(.*)',
        name: 'Page Not Found | OceanSheet',
        component: () => import('@/views/Errors/404.vue')
    }
]
