import {createStore} from 'vuex'
import auth from '@/store/modules/auth'
import connection from '@/store/modules/connection'

export default createStore({
  modules: {
    auth,
    connection,
  }
})
