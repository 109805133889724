<template>
    <div class="text-gray-800">
        <main class="w-full md:w-[calc(100%-256px)] md:ml-64 bg-gray-100 min-h-screen transition-all main">
            <!-- <Navbar></Navbar> -->
            <slot />
        </main>
    </div>
</template>

<script>
// import Navbar from '@/components/Application/Navbar.vue'

export default {
    // components: {
    //     Navbar
    // },
}
</script>