<template>
    <div class="text-gray-800">
        <!-- <Sidebar></Sidebar> -->

        <main class="w-full md:w-[calc(100%-256px)] md:ml-64 bg-gray-100 min-h-screen transition-all main">
            <!-- <Navbar></Navbar> -->
            <slot />
        </main>
    </div>
</template>

<script>
// import Sidebar from '@/components/Application/Sidebar.vue'
// import Navbar from '@/components/Application/Navbar.vue'

export default {
    // components: {
    //     Sidebar,
    //     Navbar
    // },
}
</script>